import React from "react";
import PageContentType from "./PageContentType";
import Permission from "./Permission";
import TeamInfo from "./teamInfo";
import UserInfo from "./userInfo";

type GlobalStoreContextType = {
    userInfo: UserInfo | null;
    setUserInfo(userInfo: UserInfo): Promise<boolean>;
    gottenUserInfo: boolean;
    loggedIn: boolean;
    userHasPermission: (permission: Permission, team: number, project: number | null) => boolean;

    userPic: string | null;
    setUserPic(userPic: string): void;

    reloadUserInfo: () => Promise<boolean>;

    usersTeams;

    currentTeam;
    setCurrentTeam(team_id: number): void;
    reloadCurrentTeam(): void;
    reloadUsersTeams(): void;

    currentTeamsProjects: null | {
        member_of: any[];
        not_member_of: any[];
        projects: any[];
        recent_projects: any[];
    };
    reloadCurrentTeamsProjects(): Promise<void>;

    pageContentType: PageContentType | null;
    setPageContentType(pageContentType: PageContentType): void;

    /**
      Dont use this to see if the project exists, instead use currentProjectInfo.
    */
    currentProjectToken: string | null;
    setCurrentProjectToken(projectToken: string): void;

    currentProjectInfo: any | null;
    reloadCurrentProject: () => Promise<boolean>;

    currentViewedTeamInfo: TeamInfo | null;
    reloadCurrentViewedTeamInfo: () => void;

    windowSize: { width: number; height: number };
    isMobile: boolean;

    cableApp: ActionCable.Cable | null;

    mapIcons: {
        equipmentIcons: string[];
        sdfIcons: string[];
    };

    currentProjectWeather: any | null;
    getCurrentProjectWeather: () => Promise<void>;

    projectMapLayers: any[];

    currentAppVersion: null | string;
    setCurrentAppVersion(version: number): void;

    dashboardNotificationsChannel: any | null;

    newBroadcastMessage: boolean;
    setNewBroadcastMessage(newBroadcastMessage: boolean): void;

    mapChannel: any | null;
    deliveriesChannel: any | null;

    setUserTheme(theme: string): void;

    activeSidebarKey: string;
    setActiveSidebarKey(activeSidebarKey: string): void;

    thisWeeksEvents: any[];
    collapseSidebar: boolean;
    setCollapseSidebar(collapseSidebar: boolean): void;

    showNotificationsDrawer: boolean;
    setShowNotificationsDrawer(showNotificationsDrawer: boolean): void;
};

// @ts-expect-error: This throws an error that null cannot initialize this type. This is true, but first use will give this a real value.
const GlobalStoreContext = React.createContext<GlobalStoreContextType>(null);

export default GlobalStoreContext;
